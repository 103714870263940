<template>
  <not-authorised>
    <template #left-half>
      <set-password-form />
    </template>
    <template #right-half>
      <register-link />
    </template>
  </not-authorised>
</template>

<script>
import SetPasswordForm from "../components/common/SetPasswordForm.vue";
import RegisterLink from "../components/common/RegisterLink.vue";
import NotAuthorised from "../components/common/NotAuthorised.vue";
import UserAddIcon from "../components/icons/UserAddIcon.vue";
export default {
  components: { NotAuthorised, UserAddIcon, SetPasswordForm, RegisterLink },
  name: "SetPasswordAfterEmail",
};
</script>

<style lang="scss" scoped>
.forgot-form {
  width: 50%;
}
</style>